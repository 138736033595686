@import url('https://fonts.googleapis.com/css?family=Open+Sans|Pinyon+Script|Great+Vibes|EB+Garamond|Rochester');
/* @import url('https://fonts.googleapis.com/css2?family=Great+Vibes|Open+Sans&display=swap'); */

.cursive {
  font-family: 'EB Garamond', cursive;
  font-size: 18px !important;
  line-height: 2.3em;
}

.sans {
  font-family: 'Open Sans', sans-serif;
}

.bold {
  font-weight: bold;
}

.block {
  display: block;
}

.underline {
  border-bottom: 1px solid #777;
  padding: 5px;
  margin-bottom: 15px;
}

.small-text-new {
  font-weight: 700;
  font-size: 18px !important;
}

.small-text-new-top {
  font-weight: 700;
  font-size: 11px !important;
  text-align: right;
  position: absolute;
  right: 30px;
  top: 10px;
}

.small-text-new-1 {
  font-weight: 700;
  font-size: 16px !important;
}

.title-text-new {
  font-weight: 900;
  font-size: 30px !important;
  color: red;
  margin-bottom: 10px;
}

.small-curved-new-2 {
  font-size: 14px;
  font-weight: 700;
  background-color: #46579C;
  color: white;
  padding: 2px 10px 2px 10px;
  border-radius: 10px;
}

.small-curved-new {
  font-size: 12px !important;
  border: solid 1px rgb(38, 38, 38);
  padding: 10px;
  border-radius: 10px;
  color: black
}

.reg-form1 {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.pm-empty-space {
  height: 40px;
  width: 100%;
}

body {
  padding: 20px 0;
  background: #ccc;
}


.watermark-new {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 297mm;
  width: 100%;
}

.watermark-new-img {
  width: 400px;
  opacity: 0.06;
}


.pm-certificate-container {
  position: relative;
  width: 210mm;
  height: 297mm;
  background-color: #46579C;
  padding: 20mm;
  color: #333;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);

  /* Watermark */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(0deg,
        transparent 0,
        transparent 20px,
        #ffffff1a 20px,
        #ffffff1a 22px),
      repeating-linear-gradient(90deg,
        transparent 0,
        transparent 160px,
        #ffffff1a 160px,
        #ffffff1a 162px);
    font-size: 15px;
    color: #ffffff1a;
    font-family: 'Open Sans', sans-serif;
    z-index: 1;
    pointer-events: none;
  }


  .watermark-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    font-size: 15px;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    /* 20 columns */
    grid-template-rows: repeat(25, 1fr);
    /* 25 rows */
    align-items: center;
    justify-items: center;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
  }

  .watermark-text span {
    opacity: 0.1;
    color: #000000;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    display: block;
    line-height: 1.5;
  }

  .outer-border {
    width: calc(210mm - 6mm);
    height: calc(297mm - 6mm);
    position: absolute;
    left: 50%;
    margin-left: calc(-105mm + 3mm);
    top: 50%;
    margin-top: calc(-148.5mm + 3mm);
    border: 2px solid #fff;
  }

  .inner-border {
    width: calc(210mm - 28mm);
    height: calc(297mm - 28mm);
    position: absolute;
    left: 50%;
    margin-left: calc(-105mm + 14mm);
    top: 50%;
    margin-top: calc(-148.5mm + 14mm);
    border: 2px solid #fff;
  }

  .pm-certificate-border {
    position: relative;
    width: calc(210mm - 32mm);
    height: calc(297mm - 32mm);
    padding: 0;
    border: 1px solid #E1E5F0;
    background-color: rgba(255, 255, 255, 1);
    left: 50%;
    margin-left: calc(-105mm + 16mm);
    top: 50%;
    margin-top: calc(-148.5mm + 16mm);

    .pm-certificate-block {
      width: calc(210mm - 50mm);
      height: calc(297mm / 3);
      position: relative;
      left: 50%;
      margin-left: calc(-105mm + 25mm);
      top: calc(297mm / 8);
      margin-top: 0;
    }

    .pm-certificate-header {
      margin-bottom: 10px;
    }

    .pm-certificate-title {
      position: relative;
      /* top: calc(297mm / 10); */

      h2 {
        font-size: 34px !important;
      }
    }

    .pm-certificate-body {
      padding: 20px;

      .pm-name-text {
        font-size: 20px;
      }
    }

    .pm-earned {
      margin: 15px 0 20px;

      .pm-earned-text {
        font-size: 20px;
      }

      .pm-credits-text {
        font-size: 15px;
      }
    }

    .pm-course-title {
      .pm-earned-text {
        font-size: 20px;
      }

      .pm-credits-text {
        font-size: 15px;
      }
    }

    .pm-certified {
      font-size: 12px;

      .underline {
        margin-bottom: 5px;
      }
    }

    .pm-certificate-footer {
      width: calc(210mm - 50mm);
      height: 100px;
      position: relative;
      left: 50%;
      margin-left: calc(-105mm + 25mm);
      bottom: -105px;
    }
  }
}